@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
/* line 6, node_modules/reset-css/_reset.scss */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
/* line 27, node_modules/reset-css/_reset.scss */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block; }

/* line 31, node_modules/reset-css/_reset.scss */
body {
  line-height: 1; }

/* line 34, node_modules/reset-css/_reset.scss */
ol, ul {
  list-style: none; }

/* line 37, node_modules/reset-css/_reset.scss */
blockquote, q {
  quotes: none; }
  /* line 39, node_modules/reset-css/_reset.scss */
  blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none; }

/* line 44, node_modules/reset-css/_reset.scss */
table {
  border-collapse: collapse;
  border-spacing: 0; }

/* Slider */
/* line 3, node_modules/slick-carousel/slick/slick.scss */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

/* line 17, node_modules/slick-carousel/slick/slick.scss */
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  /* line 24, node_modules/slick-carousel/slick/slick.scss */
  .slick-list:focus {
    outline: none; }
  /* line 28, node_modules/slick-carousel/slick/slick.scss */
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

/* line 33, node_modules/slick-carousel/slick/slick.scss */
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/* line 42, node_modules/slick-carousel/slick/slick.scss */
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  /* line 48, node_modules/slick-carousel/slick/slick.scss */
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  /* line 54, node_modules/slick-carousel/slick/slick.scss */
  .slick-track:after {
    clear: both; }
  /* line 58, node_modules/slick-carousel/slick/slick.scss */
  .slick-loading .slick-track {
    visibility: hidden; }

/* line 62, node_modules/slick-carousel/slick/slick.scss */
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  /* line 66, node_modules/slick-carousel/slick/slick.scss */
  [dir="rtl"] .slick-slide {
    float: right; }
  /* line 69, node_modules/slick-carousel/slick/slick.scss */
  .slick-slide img {
    display: block; }
  /* line 72, node_modules/slick-carousel/slick/slick.scss */
  .slick-slide.slick-loading img {
    display: none; }
  /* line 78, node_modules/slick-carousel/slick/slick.scss */
  .slick-slide.dragging img {
    pointer-events: none; }
  /* line 82, node_modules/slick-carousel/slick/slick.scss */
  .slick-initialized .slick-slide {
    display: block; }
  /* line 86, node_modules/slick-carousel/slick/slick.scss */
  .slick-loading .slick-slide {
    visibility: hidden; }
  /* line 90, node_modules/slick-carousel/slick/slick.scss */
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

/* line 96, node_modules/slick-carousel/slick/slick.scss */
.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
/* line 45, node_modules/slick-carousel/slick/slick-theme.scss */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
/* line 63, node_modules/slick-carousel/slick/slick-theme.scss */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  /* line 81, node_modules/slick-carousel/slick/slick-theme.scss */
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    /* line 85, node_modules/slick-carousel/slick/slick-theme.scss */
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  /* line 89, node_modules/slick-carousel/slick/slick-theme.scss */
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  /* line 92, node_modules/slick-carousel/slick/slick-theme.scss */
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

/* line 103, node_modules/slick-carousel/slick/slick-theme.scss */
.slick-prev {
  left: -25px; }
  /* line 105, node_modules/slick-carousel/slick/slick-theme.scss */
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  /* line 109, node_modules/slick-carousel/slick/slick-theme.scss */
  .slick-prev:before {
    content: "←"; }
    /* line 111, node_modules/slick-carousel/slick/slick-theme.scss */
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

/* line 117, node_modules/slick-carousel/slick/slick-theme.scss */
.slick-next {
  right: -25px; }
  /* line 119, node_modules/slick-carousel/slick/slick-theme.scss */
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  /* line 123, node_modules/slick-carousel/slick/slick-theme.scss */
  .slick-next:before {
    content: "→"; }
    /* line 125, node_modules/slick-carousel/slick/slick-theme.scss */
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
/* line 133, node_modules/slick-carousel/slick/slick-theme.scss */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

/* line 137, node_modules/slick-carousel/slick/slick-theme.scss */
.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  /* line 146, node_modules/slick-carousel/slick/slick-theme.scss */
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    /* line 154, node_modules/slick-carousel/slick/slick-theme.scss */
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      /* line 166, node_modules/slick-carousel/slick/slick-theme.scss */
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        /* line 168, node_modules/slick-carousel/slick/slick-theme.scss */
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      /* line 172, node_modules/slick-carousel/slick/slick-theme.scss */
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    /* line 189, node_modules/slick-carousel/slick/slick-theme.scss */
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/* line 1, doc/scss/foundation/_base.scss */
html {
  font-size: 62.5%; }

/* line 5, doc/scss/foundation/_base.scss */
body {
  width: 100%;
  color: #333;
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "MS PGothic", arial, helvetica, sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-print-color-adjust: exact; }

/* line 14, doc/scss/foundation/_base.scss */
*, *:after, *:before {
  background-size: 100%;
  background-repeat: no-repeat;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch; }

/* line 21, doc/scss/foundation/_base.scss */
a {
  display: block;
  width: 100%;
  height: 100%;
  color: #333;
  text-decoration: none;
  transition: color 0.15s linear; }
  /* line 29, doc/scss/foundation/_base.scss */
  a:hover {
    color: #4990E2;
    transition: color 0.15s linear; }

/* line 35, doc/scss/foundation/_base.scss */
img {
  width: 100%;
  border: 0;
  vertical-align: top; }

/* line 1, doc/scss/layout/_share.scss */
.l-container {
  max-width: 1200px;
  height: 100%;
  margin: auto;
  padding: 0 10px; }
  @media all and (min-width: 751px) {
    /* line 1, doc/scss/layout/_share.scss */
    .l-container {
      padding: 0 15px; } }

/* line 19, doc/scss/layout/_flex.scss */
.l-flex-inline {
  display: inline-flex; }

/* line 23, doc/scss/layout/_flex.scss */
.l-flex {
  display: flex; }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .l-flex--fd-row {
    flex-direction: row; }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .l-flex--fd-row-reverse {
    flex-direction: row-reverse; }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .l-flex--fd-column {
    flex-direction: column; }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .l-flex--fd-bp-column-row {
    flex-direction: column; }
    @media all and (min-width: 751px) {
      /* line 126, doc/scss/foundation/_mixin.scss */
      .l-flex--fd-bp-column-row {
        flex-direction: row; } }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .l-flex--fd-column-reverse {
    flex-direction: column-reverse; }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .l-flex--fd-bp-column-reverse {
    flex-direction: column; }
    @media all and (min-width: 751px) {
      /* line 126, doc/scss/foundation/_mixin.scss */
      .l-flex--fd-bp-column-reverse {
        flex-direction: column-reverse; } }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .l-flex--fw-wrap {
    flex-wrap: wrap; }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .l-flex--fw-nowrap {
    flex-wrap: nowrap; }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .l-flex--fw-wrap-reverse {
    flex-wrap: wrap-reverse; }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .l-flex--jc-flex-start {
    justify-content: flex-start; }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .l-flex--jc-flex-end {
    justify-content: flex-end; }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .l-flex--jc-center {
    justify-content: center; }
    @media all and (max-width: 750px) {
      /* line 126, doc/scss/foundation/_mixin.scss */
      .l-flex--jc-center {
        justify-content: flex-start; } }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .l-flex--jc-space-between {
    justify-content: space-between; }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .l-flex--jc-space-around {
    justify-content: space-around; }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .l-flex--ai-stretch {
    align-items: stretch; }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .l-flex--ai-flex-start {
    align-items: flex-start; }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .l-flex--ai-flex-end {
    align-items: flex-end; }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .l-flex--ai-center {
    align-items: center; }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .l-flex--ai-baseline {
    align-items: baseline; }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .l-flex--ac-stretch {
    align-content: stretch; }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .l-flex--ac-flex-start {
    align-content: flex-start; }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .l-flex--ac-flex-end {
    align-content: flex-end; }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .l-flex--ac-center {
    align-content: center; }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .l-flex--ac-space-between {
    align-content: space-between; }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .l-flex--ac-space-around {
    align-content: space-around; }

/* line 1, doc/scss/layout/_header.scss */
#header {
  padding: 15px 0; }
  @media all and (max-width: 750px) {
    /* line 1, doc/scss/layout/_header.scss */
    #header {
      border-bottom: 1px solid #DFDFDF; } }

/* line 7, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
  order: 0; }

/* line 7, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
  order: 0; }

/* line 7, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-3 {
  flex: 0 0 25%;
  max-width: 25%;
  order: 0; }

/* line 7, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  order: 0; }

/* line 7, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
  order: 0; }

/* line 7, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-6 {
  flex: 0 0 50%;
  max-width: 50%;
  order: 0; }

/* line 7, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
  order: 0; }

/* line 7, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
  order: 0; }

/* line 7, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-9 {
  flex: 0 0 75%;
  max-width: 75%;
  order: 0; }

/* line 7, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
  order: 0; }

/* line 7, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
  order: 0; }

/* line 7, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-12 {
  flex: 0 0 100%;
  max-width: 100%;
  order: 0; }

/* line 16, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-offset-0 {
  margin-left: 0%; }

/* line 16, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-offset-1 {
  margin-left: 8.33333%; }

/* line 16, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-offset-2 {
  margin-left: 16.66667%; }

/* line 16, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-offset-3 {
  margin-left: 25%; }

/* line 16, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-offset-4 {
  margin-left: 33.33333%; }

/* line 16, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-offset-5 {
  margin-left: 41.66667%; }

/* line 16, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-offset-6 {
  margin-left: 50%; }

/* line 16, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-offset-7 {
  margin-left: 58.33333%; }

/* line 16, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-offset-8 {
  margin-left: 66.66667%; }

/* line 16, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-offset-9 {
  margin-left: 75%; }

/* line 16, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-offset-10 {
  margin-left: 83.33333%; }

/* line 16, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-offset-11 {
  margin-left: 91.66667%; }

/* line 21, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-order-first {
  order: -1; }

/* line 24, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-order-last {
  order: 1; }

/* line 27, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-align-start {
  align-self: flex-start; }

/* line 30, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-align-end {
  align-self: flex-end; }

/* line 33, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-align-center {
  align-self: center; }

/* line 36, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-align-baseline {
  align-self: baseline; }

/* line 39, doc/scss/object/component/_grid-sp.scss */
.c-col-sp-align-stretch {
  align-self: flex-stretch; }

@media all and (max-width: 750px) {
  /* line 43, doc/scss/object/component/_grid-sp.scss */
  .c-col-sp-hidden {
    display: none; } }

@media all and (min-width: 751px) {
  /* line 8, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
    order: 0; }
  /* line 8, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
    order: 0; }
  /* line 8, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-3 {
    flex: 0 0 25%;
    max-width: 25%;
    order: 0; }
  /* line 8, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    order: 0; }
  /* line 8, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
    order: 0; }
  /* line 8, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-6 {
    flex: 0 0 50%;
    max-width: 50%;
    order: 0; }
  /* line 8, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
    order: 0; }
  /* line 8, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
    order: 0; }
  /* line 8, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-9 {
    flex: 0 0 75%;
    max-width: 75%;
    order: 0; }
  /* line 8, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    order: 0; }
  /* line 8, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
    order: 0; }
  /* line 8, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-12 {
    flex: 0 0 100%;
    max-width: 100%;
    order: 0; }
  /* line 17, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-offset-0 {
    margin-left: 0%; }
  /* line 17, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-offset-1 {
    margin-left: 8.33333%; }
  /* line 17, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-offset-2 {
    margin-left: 16.66667%; }
  /* line 17, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-offset-3 {
    margin-left: 25%; }
  /* line 17, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-offset-4 {
    margin-left: 33.33333%; }
  /* line 17, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-offset-5 {
    margin-left: 41.66667%; }
  /* line 17, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-offset-6 {
    margin-left: 50%; }
  /* line 17, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-offset-7 {
    margin-left: 58.33333%; }
  /* line 17, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-offset-8 {
    margin-left: 66.66667%; }
  /* line 17, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-offset-9 {
    margin-left: 75%; }
  /* line 17, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-offset-10 {
    margin-left: 83.33333%; }
  /* line 17, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-offset-11 {
    margin-left: 91.66667%; }
  /* line 22, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-order-first {
    order: -1; }
  /* line 25, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-order-last {
    order: 1; }
  /* line 28, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-align-start {
    align-self: flex-start; }
  /* line 31, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-align-end {
    align-self: flex-end; }
  /* line 34, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-align-center {
    align-self: center; }
  /* line 37, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-align-baseline {
    align-self: baseline; }
  /* line 40, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-align-stretch {
    align-self: flex-stretch; }
  /* line 43, doc/scss/object/component/_grid-pc.scss */
  .c-col-pc-hidden {
    display: none; } }

/* line 19, doc/scss/object/component/_button.scss */
.c-button {
  display: inline-block;
  width: auto;
  padding: 5px 20px;
  background-image: none;
  border: 1px solid transparent;
  cursor: pointer;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.4;
  text-align: center;
  transform: background "0.15s linear", color "0.15s linear", border "0.15s linear";
  white-space: nowrap;
  vertical-align: middle; }
  @media all and (min-width: 751px) {
    /* line 19, doc/scss/object/component/_button.scss */
    .c-button {
      padding: 8px 40px;
      font-size: 1.6rem; } }
  /* line 39, doc/scss/object/component/_button.scss */
  .c-button:hover {
    transform: background "0.15s linear", color "0.15s linear", border "0.15s linear"; }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .c-button--frame {
    border: 3px solid transparent; }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .c-button--arrow {
    position: relative;
    border: 3px solid transparent; }
    /* line 52, doc/scss/object/component/_button.scss */
    .c-button--arrow::after {
      position: absolute; }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .c-button--sm {
    padding: 5px 10px;
    font-size: 0.9rem; }
    @media all and (min-width: 751px) {
      /* line 126, doc/scss/foundation/_mixin.scss */
      .c-button--sm {
        padding: 6px 15px;
        font-size: 1.2rem; } }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .c-button--br-max {
    border-radius: 9999px; }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .c-button--br-circle {
    border-radius: 50%; }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .c-button--color-base {
    border-color: #4990E2;
    color: #4990E2; }
    /* line 88, doc/scss/object/component/_button.scss */
    .c-button--color-base:hover {
      background: #4990E2;
      color: #fff; }

/* line 85, doc/scss/foundation/_mixin.scss */
.p-footer__highlight {
  background: #e1e1e1; }

/* line 7, doc/scss/object/project/_footer.scss */
.p-footer-navigations {
  padding: 25px 0; }
  @media all and (min-width: 751px) {
    /* line 7, doc/scss/object/project/_footer.scss */
    .p-footer-navigations {
      padding: 60px 0; } }

@media all and (max-width: 750px) {
  /* line 15, doc/scss/object/project/_footer.scss */
  .p-footer-navigations-container {
    padding: 0 30px; } }

/* line 21, doc/scss/object/project/_footer.scss */
.p-footer-navigation {
  padding: 7px 0; }
  @media all and (min-width: 751px) {
    /* line 21, doc/scss/object/project/_footer.scss */
    .p-footer-navigation {
      padding: 0 10px; } }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .p-footer-navigation__heading {
    margin-bottom: 15px;
    color: #4A4A4A;
    font-size: 1.2rem; }
    @media all and (min-width: 751px) {
      /* line 85, doc/scss/foundation/_mixin.scss */
      .p-footer-navigation__heading {
        margin-bottom: 20px;
        font-size: 1.6rem;
        line-height: 1.4; } }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .p-footer-navigation__toggle {
    position: relative;
    display: inline-block;
    width: auto; }
    @media all and (max-width: 750px) {
      /* line 85, doc/scss/foundation/_mixin.scss */
      .p-footer-navigation__toggle {
        padding-left: 15px; } }
    @media all and (min-width: 751px) {
      /* line 85, doc/scss/foundation/_mixin.scss */
      .p-footer-navigation__toggle {
        cursor: default; } }
    @media all and (max-width: 750px) {
      /* line 53, doc/scss/object/project/_footer.scss */
      .p-footer-navigation__toggle::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 6px;
        height: 6px;
        margin-top: -3px;
        border: 0px;
        border-top: solid 1px #4A4A4A;
        border-right: solid 1px #4A4A4A;
        transform: rotate(45deg); } }
    /* line 70, doc/scss/object/project/_footer.scss */
    .p-footer-navigation__toggle:hover {
      color: #333; }
    @media all and (max-width: 750px) {
      /* line 76, doc/scss/object/project/_footer.scss */
      .p-footer-navigation__toggle.is-open::before {
        transform: rotate(135deg); } }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .p-footer-navigation__link {
    position: relative;
    display: inline-block;
    width: auto;
    margin-bottom: 10px;
    padding-left: 15px;
    color: #4A4A4A;
    font-size: 0.9rem;
    line-height: 1.4; }
    @media all and (min-width: 751px) {
      /* line 85, doc/scss/foundation/_mixin.scss */
      .p-footer-navigation__link {
        margin-bottom: 15px;
        font-size: 1.2em; } }
    /* line 99, doc/scss/object/project/_footer.scss */
    .p-footer-navigation__link::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 5px;
      height: 5px;
      margin-top: -2px;
      border: 0px;
      border-top: solid 1px #4A4A4A;
      border-right: solid 1px #4A4A4A;
      transform: rotate(45deg); }
      @media all and (min-width: 751px) {
        /* line 99, doc/scss/object/project/_footer.scss */
        .p-footer-navigation__link::before {
          width: 6px;
          height: 6px;
          margin-top: -4px; } }
    /* line 119, doc/scss/object/project/_footer.scss */
    .p-footer-navigation__link:hover {
      transition: border 0.01s linear; }
      /* line 121, doc/scss/object/project/_footer.scss */
      .p-footer-navigation__link:hover::before {
        border-color: #4990E2;
        transition: border 0.01s linear; }

/* line 129, doc/scss/object/project/_footer.scss */
.p-footer-copylight {
  display: block;
  width: 100%;
  margin: auto;
  padding: 20px 0;
  color: #4A4A4A;
  font-size: 1rem;
  text-align: center; }
  @media all and (min-width: 751px) {
    /* line 129, doc/scss/object/project/_footer.scss */
    .p-footer-copylight {
      padding: 30px 0;
      font-size: 1.3rem; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.p-header__heading {
  width: 140px;
  height: 21px;
  background-image: url("../img/header/global-software__logo--phone.png");
  line-height: 40px;
  overflow: hidden;
  text-indent: 100%; }
  @media all and (min-width: 751px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .p-header__heading {
      width: 270px;
      height: 40px;
      background-image: url("../img/header/global-software__logo--pc.png"); } }

/* line 85, doc/scss/foundation/_mixin.scss */
.p-header__contact {
  color: #333; }

/* line 22, doc/scss/object/project/_header.scss */
.p-header-actions {
  padding-top: 0; }
  @media all and (min-width: 751px) {
    /* line 22, doc/scss/object/project/_header.scss */
    .p-header-actions {
      padding: 5px 0; } }

/* line 30, doc/scss/object/project/_header.scss */
.p-header-action {
  display: inline-block;
  width: auto;
  height: 21px;
  margin-left: 7px;
  font-size: 0.9rem;
  line-height: 21px;
  text-align: center;
  vertical-align: top; }
  @media all and (min-width: 751px) {
    /* line 30, doc/scss/object/project/_header.scss */
    .p-header-action {
      height: 30px;
      margin-left: 15px;
      font-size: 1.2rem;
      line-height: 30px; } }

@media all and (max-width: 750px) {
  /* line 2, doc/scss/object/project/_corporate.scss */
  .p-corporate-container {
    padding: 0 15px;
    margin-bottom: 60px; } }

@media all and (min-width: 751px) {
  /* line 2, doc/scss/object/project/_corporate.scss */
  .p-corporate-container {
    margin: 80px auto 100px;
    padding: 0 50px; } }

/* line 14, doc/scss/object/project/_corporate.scss */
.p-corporate-navigations {
  margin-top: -10px; }
  @media all and (max-width: 750px) {
    /* line 14, doc/scss/object/project/_corporate.scss */
    .p-corporate-navigations {
      margin-bottom: 40px; } }
  @media all and (min-width: 751px) {
    /* line 14, doc/scss/object/project/_corporate.scss */
    .p-corporate-navigations {
      margin-top: 30px; } }

@media all and (max-width: 750px) {
  /* line 26, doc/scss/object/project/_corporate.scss */
  .p-corporate-navigations-container {
    border-top: 1px solid #4990E2;
    border-bottom: 1px solid #4990E2; } }

@media all and (max-width: 750px) {
  /* line 33, doc/scss/object/project/_corporate.scss */
  .p-corporate-navigation-container {
    position: relative;
    height: 50px;
    border-bottom: 1px solid #4990E2; } }

@media all and (min-width: 751px) {
  /* line 33, doc/scss/object/project/_corporate.scss */
  .p-corporate-navigation-container {
    padding: 15px 0;
    border-top: 1px solid #4990E2; } }

@media all and (max-width: 750px) {
  /* line 48, doc/scss/object/project/_corporate.scss */
  .p-corporate-navigation:nth-of-type(odd) {
    padding-right: 10px; }
  /* line 52, doc/scss/object/project/_corporate.scss */
  .p-corporate-navigation:nth-of-type(even) {
    padding-left: 10px; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.p-corporate-navigation__heading {
  width: 100%;
  margin: auto;
  min-height: 36px;
  font-size: 1.2rem;
  line-height: 1.2; }
  @media all and (max-width: 750px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .p-corporate-navigation__heading {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0; } }
  @media all and (min-width: 751px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .p-corporate-navigation__heading {
      position: relative;
      min-height: 30px;
      border-radius: 15px;
      font-size: 1.5rem; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.p-corporate-navigation__link {
  position: relative;
  height: auto;
  padding: 8px 25px 8px 15px; }
  @media all and (max-width: 750px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .p-corporate-navigation__link {
      vertical-align: middle; } }
  @media all and (min-width: 751px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .p-corporate-navigation__link {
      padding: 8px 20px 8px 15px; } }
  /* line 94, doc/scss/object/project/_corporate.scss */
  .p-corporate-navigation__link::after {
    content: '';
    position: absolute;
    top: 12px;
    right: 15px;
    width: 8px;
    height: 8px;
    border: 0px;
    border-top: solid 2px #4990E2;
    border-right: solid 2px #4990E2;
    transform: rotate(45deg); }
  /* line 126, doc/scss/foundation/_mixin.scss */
  .p-corporate-navigation__link--is-active {
    background: #DCECFE;
    border-radius: 16px; }

/* line 85, doc/scss/foundation/_mixin.scss */
.p-corporate-navigation__text {
  margin-top: 5px;
  padding-left: 30px;
  font-size: 1.3rem;
  line-height: 2; }

/* line 122, doc/scss/object/project/_corporate.scss */
.p-corporate-contents {
  font-size: 1.2rem;
  line-height: 1.6; }
  @media all and (min-width: 751px) {
    /* line 122, doc/scss/object/project/_corporate.scss */
    .p-corporate-contents {
      padding-left: 60px;
      font-size: 1.4rem;
      line-height: 2; } }

/* line 133, doc/scss/object/project/_corporate.scss */
.p-corporate-content-container {
  padding-left: 15px;
  font-size: 1.2rem;
  line-height: 1.6; }
  @media all and (max-width: 750px) {
    /* line 133, doc/scss/object/project/_corporate.scss */
    .p-corporate-content-container {
      margin-top: 10px; } }
  @media all and (min-width: 751px) {
    /* line 133, doc/scss/object/project/_corporate.scss */
    .p-corporate-content-container {
      padding-left: 60px;
      font-size: 1.5rem;
      line-height: 1.8; } }

/* line 149, doc/scss/object/project/_corporate.scss */
.p-corporate-content {
  margin-bottom: 40px; }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .p-corporate-content__heading {
    position: relative;
    font-size: 1.6rem;
    margin-bottom: 15px;
    padding: 0 0 8px;
    border-bottom: 1px solid #979797;
    line-height: 1; }
    @media all and (min-width: 751px) {
      /* line 85, doc/scss/foundation/_mixin.scss */
      .p-corporate-content__heading {
        font-size: 2rem;
        margin-bottom: 50px; } }
    /* line 166, doc/scss/object/project/_corporate.scss */
    .p-corporate-content__heading::before {
      content: " ";
      position: absolute;
      bottom: -3px;
      width: 130px;
      height: 0;
      border-bottom: 5px solid #4990E2; }
      @media all and (min-width: 751px) {
        /* line 166, doc/scss/object/project/_corporate.scss */
        .p-corporate-content__heading::before {
          width: 200px; } }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .p-corporate-content__title {
    width: 80px;
    flex: 0 0 80px;
    white-space: nowrap; }
    @media all and (min-width: 751px) {
      /* line 85, doc/scss/foundation/_mixin.scss */
      .p-corporate-content__title {
        width: 110px;
        flex: 0 0 110px; } }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .p-corporate-content__text {
    width: calc(100% - 80px);
    flex: 0 0 calc(100% - 80px);
    padding-left: 20px; }
    @media all and (min-width: 751px) {
      /* line 85, doc/scss/foundation/_mixin.scss */
      .p-corporate-content__text {
        width: calc(100% - 110px);
        flex: 0 0 calc(100% - 110px);
        padding-left: 30px; } }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .p-corporate-content__textbox {
    width: 100%;
    height: 380px;
    padding: 15px;
    background: #EFEFEF;
    border: 1px solid #979797;
    overflow: scroll; }
    @media all and (min-width: 751px) {
      /* line 85, doc/scss/foundation/_mixin.scss */
      .p-corporate-content__textbox {
        height: 700px;
        padding: 40px; } }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .p-corporate-content__link {
    display: inline-block;
    width: auto;
    height: auto;
    color: #4990E2;
    line-height: 1.2;
    word-break: break-all; }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .p-corporate-content__center-image {
    max-width: 400px;
    margin: 0 auto 20px;
    padding: 0 40px; }
    @media all and (min-width: 751px) {
      /* line 85, doc/scss/foundation/_mixin.scss */
      .p-corporate-content__center-image {
        max-width: 440px;
        margin: 0 auto 120px;
        padding: 0 20px; } }

/* line 1, doc/scss/object/project/_navigation.scss */
.p-navigations {
  padding-bottom: 20px; }
  @media all and (min-width: 751px) {
    /* line 1, doc/scss/object/project/_navigation.scss */
    .p-navigations {
      padding-bottom: 15px;
      border-bottom: 1px solid #DFDFDF; } }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .p-navigations__button {
    position: relative;
    margin: 15px 0 0 15px;
    width: 26px;
    height: 26px;
    background: #D8D8D8;
    border-radius: 7px; }
    /* line 17, doc/scss/object/project/_navigation.scss */
    .p-navigations__button::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 8px;
      height: 8px;
      margin-top: -3px;
      margin-left: -3px;
      border: 0px;
      border-top: solid 2px #fff;
      border-right: solid 2px #fff;
      opacity: 1;
      transform: rotate(-135deg); }

@media all and (max-width: 750px) {
  /* line 35, doc/scss/object/project/_navigation.scss */
  .p-navigations-container {
    padding: 0; } }

@media all and (max-width: 750px) {
  /* line 41, doc/scss/object/project/_navigation.scss */
  .p-navigation {
    border-bottom: 1px solid #DFDFDF; } }

@media all and (min-width: 751px) {
  /* line 41, doc/scss/object/project/_navigation.scss */
  .p-navigation {
    border-left: 1px solid #DFDFDF; } }

@media all and (max-width: 750px) {
  /* line 55, doc/scss/object/project/_navigation.scss */
  .p-navigation:nth-of-type(2n-1) {
    border-right: 1px solid #DFDFDF; } }

@media all and (min-width: 751px) {
  /* line 61, doc/scss/object/project/_navigation.scss */
  .p-navigation:last-child {
    border-right: 1px solid #DFDFDF; } }

@media all and (max-width: 750px) {
  /* line 126, doc/scss/foundation/_mixin.scss */
  .p-navigation--is-active {
    margin-top: 15px;
    padding: 0 10px;
    border: 0; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.p-navigation__heading {
  height: 40px;
  font-size: 1.2rem;
  line-height: 18px; }
  @media all and (min-width: 751px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .p-navigation__heading {
      height: 30px;
      font-size: 1.3rem;
      line-height: 30px;
      text-align: center; } }
  @media all and (max-width: 750px) {
    /* line 126, doc/scss/foundation/_mixin.scss */
    .p-navigation__heading--is-active {
      height: auto;
      line-height: 26px; } }

@media all and (max-width: 750px) {
  /* line 85, doc/scss/foundation/_mixin.scss */
  .p-navigation__link {
    padding: 11px 15px; } }

@media all and (max-width: 750px) {
  /* line 100, doc/scss/object/project/_navigation.scss */
  .p-navigation__link::before {
    content: " ";
    display: inline-block;
    width: 2px;
    height: 18px;
    margin-right: 7px;
    background: #4990E2;
    vertical-align: top; } }

/* line 126, doc/scss/foundation/_mixin.scss */
.p-navigation__link--is-active {
  position: relative; }
  @media all and (max-width: 750px) {
    /* line 126, doc/scss/foundation/_mixin.scss */
    .p-navigation__link--is-active {
      font-size: 2rem;
      padding: 0; } }
  /* line 120, doc/scss/object/project/_navigation.scss */
  .p-navigation__link--is-active::before {
    margin-top: 3px;
    width: 4px;
    height: 20px;
    border-radius: 2px; }
  @media all and (min-width: 751px) {
    /* line 127, doc/scss/object/project/_navigation.scss */
    .p-navigation__link--is-active::after {
      content: " ";
      position: absolute;
      bottom: 0;
      display: block;
      width: 70%;
      width: calc(100% - 60px);
      height: 4px;
      margin: 0 30px;
      background: #4990E2;
      border-radius: 2px; } }

/* line 3, doc/scss/object/page/_easyKarte.scss */
.hero-images__heading-easykarte {
  padding: 0; }

/* line 85, doc/scss/foundation/_mixin.scss */
.hero-image__header-nav1 {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0.4rem; }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .hero-image__header-nav1__link {
    font-size: 1.3rem;
    text-align: center;
    padding: 0.2rem 0;
    width: 10%; }
    @media all and (max-width: 750px) {
      /* line 85, doc/scss/foundation/_mixin.scss */
      .hero-image__header-nav1__link {
        width: 25%; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.hero-image__header-nav2 {
  color: #ffffff;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  padding: 0.6rem 0; }
  @media all and (max-width: 750px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .hero-image__header-nav2 {
      font-size: 1.6rem; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.easy-karte__header-nav1 {
  background-color: #CCE4FF; }

/* line 85, doc/scss/foundation/_mixin.scss */
.easy-karte__header-nav2 {
  background-color: #4A90E2; }

/* line 47, doc/scss/object/page/_easyKarte.scss */
.easy-karte__active {
  background: #81cbfc;
  border-radius: 8px; }

/* line 85, doc/scss/foundation/_mixin.scss */
.easy-karte__product {
  display: flex;
  justify-content: center;
  padding: 0 15px; }
  @media all and (max-width: 750px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .easy-karte__product {
      display: block;
      padding: 0 15px; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.easy-karte__product1 {
  display: flex; }

/* line 85, doc/scss/foundation/_mixin.scss */
.easy-karte__product2 {
  display: flex; }

/* line 85, doc/scss/foundation/_mixin.scss */
.easy-karte__product-conts {
  margin: 0 0.8rem 2rem 0.8rem;
  padding: 2rem 3.5rem;
  text-align: center;
  box-shadow: 2px 2px 5px #cecece;
  background-color: #ECF5FF;
  border: solid 1px #4A90E2;
  border-radius: 15px; }
  @media all and (max-width: 750px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .easy-karte__product-conts {
      padding: 1.5rem;
      margin: 1rem 0.5rem 0 0.5rem;
      width: 100%; } }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .easy-karte__product-conts__txt {
    font-size: 1.5rem;
    line-height: 1.3;
    text-align: center; }
    @media all and (max-width: 750px) {
      /* line 85, doc/scss/foundation/_mixin.scss */
      .easy-karte__product-conts__txt {
        font-size: 1.5rem; } }
    @media all and (max-width: 750px) {
      /* line 99, doc/scss/object/page/_easyKarte.scss */
      .easy-karte__product-conts__txt br {
        display: none; } }

/* line 109, doc/scss/object/page/_easyKarte.scss */
.product-conts2 {
  background-color: #FFF8ED;
  border-color: #F5A623; }

/* line 113, doc/scss/object/page/_easyKarte.scss */
.product-conts3 {
  background-color: #EEF9E2;
  border-color: #7ED321; }

/* line 117, doc/scss/object/page/_easyKarte.scss */
.product-conts4 {
  background-color: #FFF3F5;
  border-color: #FFA1AD; }

@media all and (max-width: 750px) {
  /* line 124, doc/scss/object/page/_easyKarte.scss */
  .easy-karte__labels {
    margin: 1rem 0; } }

/* line 131, doc/scss/object/page/_easyKarte.scss */
.label-btn {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.3;
  margin: 1rem; }
  @media all and (max-width: 750px) {
    /* line 131, doc/scss/object/page/_easyKarte.scss */
    .label-btn {
      color: #5e5e5e;
      display: block;
      background: linear-gradient(#ffffff, #e6e6e6);
      border-top: solid 1px #e6e6e6;
      font-size: 1.5rem;
      font-weight: bold;
      padding: 2rem;
      margin: 0; } }

@media all and (min-width: 751px) {
  /* line 85, doc/scss/foundation/_mixin.scss */
  .ek__label1 {
    color: #4A90E2; }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .ek__label2 {
    color: #F5A623; }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .ek__label3 {
    color: #7ED321; }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .ek__label4 {
    color: #FC819C; } }

@media all and (max-width: 750px) {
  /* line 169, doc/scss/object/page/_easyKarte.scss */
  .label-btn:after {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    right: 15px;
    background: transparent url(../img/easy-karte/button-01.png) center center; }
  /* line 178, doc/scss/object/page/_easyKarte.scss */
  .easy-karte__label:checked + .label-btn:after {
    background: transparent url(../img/easy-karte/button-02.png) center center; } }

/* line 184, doc/scss/object/page/_easyKarte.scss */
.easy-karte__label-txt {
  font-size: 1.5rem;
  line-height: 1.6;
  margin-top: -1rem; }
  @media all and (max-width: 750px) {
    /* line 184, doc/scss/object/page/_easyKarte.scss */
    .easy-karte__label-txt {
      height: 0;
      padding: 0;
      margin: 0;
      overflow: hidden;
      opacity: 0;
      transition: 0.8s; } }

/* line 199, doc/scss/object/page/_easyKarte.scss */
.easy-karte__label-txt h4 {
  font-weight: bold;
  text-indent: -2.8rem;
  padding-left: 2.8rem;
  line-height: 1.5;
  margin: 0.8rem 0; }

/* line 208, doc/scss/object/page/_easyKarte.scss */
.product-txt p {
  padding-left: 3rem; }

/* line 213, doc/scss/object/page/_easyKarte.scss */
.easy-karte__label-txt h4::before {
  display: inline-block;
  vertical-align: middle;
  content: ' ';
  width: 1em;
  height: 1em;
  background: #4a90e2;
  border-radius: 50%;
  margin-right: 15px; }

/* line 225, doc/scss/object/page/_easyKarte.scss */
.easy-karte__label-img {
  background-image: url(../img/easy-karte/ek__feature01-1.png);
  height: 255px;
  background-size: contain;
  background-position: center center;
  width: 45%; }
  @media all and (max-width: 750px) {
    /* line 225, doc/scss/object/page/_easyKarte.scss */
    .easy-karte__label-img {
      width: 100%;
      margin: 0 0 2rem 0;
      opacity: 1; } }

@media all and (min-width: 751px) {
  /* line 85, doc/scss/foundation/_mixin.scss */
  .ek__label-img1-1 {
    margin-top: -2rem; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.ek__label-img1-2 {
  background-image: url(../img/easy-karte/ek__feature01-2.png);
  height: 215px; }

/* line 85, doc/scss/foundation/_mixin.scss */
.ek__label-img2-1 {
  background-image: url(../img/easy-karte/ek__feature02-1.png);
  height: 225px; }
  @media all and (min-width: 751px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .ek__label-img2-1 {
      margin-top: -2rem; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.ek__label-img2-2 {
  background-image: url(../img/easy-karte/ek__feature02-2.png);
  height: 200px; }

/* line 85, doc/scss/foundation/_mixin.scss */
.ek__label-img2-3 {
  background-image: url(../img/easy-karte/ek__feature02-3.png);
  height: 195px; }

/* line 85, doc/scss/foundation/_mixin.scss */
.ek__label-img3-1 {
  background-image: url(../img/easy-karte/ek__feature03-1.png);
  height: 475px; }
  @media all and (min-width: 751px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .ek__label-img3-1 {
      margin-top: -24rem; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.ek__label-img3-2 {
  background-image: url(../img/easy-karte/ek__feature03-2.png);
  height: 190px; }

/* line 85, doc/scss/foundation/_mixin.scss */
.ek__label-img4-1 {
  background-image: url(../img/easy-karte/ek__feature04-1.png);
  height: 175px; }

/* line 85, doc/scss/foundation/_mixin.scss */
.ek__label-img4-2 {
  background-image: url(../img/easy-karte/ek__feature04-2.png);
  height: 175px; }

/* line 85, doc/scss/foundation/_mixin.scss */
.ek__label-img4-3 {
  background-image: url(../img/easy-karte/ek__feature04-3.png);
  height: 175px; }

@media all and (min-width: 751px) {
  /* line 289, doc/scss/object/page/_easyKarte.scss */
  .ek__label-txt__outer {
    display: flex; }
  /* line 292, doc/scss/object/page/_easyKarte.scss */
  .ek__label-txt__inner {
    padding: 2rem;
    width: 55%; } }

@media all and (max-width: 750px) {
  /* line 307, doc/scss/object/page/_easyKarte.scss */
  .easy-karte__label:checked + label + .easy-karte__label-txt {
    height: auto;
    font-size: 1.4rem;
    line-height: 1.8;
    padding: 1rem 2rem;
    opacity: 1; }
  /* line 315, doc/scss/object/page/_easyKarte.scss */
  .easy-karte__label:checked + label + .easy-karte__label-txt + .easy-karte__label-img-sp {
    height: 170px;
    opacity: 1; } }

/* line 322, doc/scss/object/page/_easyKarte.scss */
.easy-karte__label-inner {
  background: #fff;
  border-radius: 15px;
  padding: 2rem 3rem;
  margin: 2rem 5rem; }
  @media all and (max-width: 750px) {
    /* line 322, doc/scss/object/page/_easyKarte.scss */
    .easy-karte__label-inner {
      width: 100%;
      padding: 0;
      margin: 0; } }

/* line 335, doc/scss/object/page/_easyKarte.scss */
.easy-karte__label-box {
  padding: 1rem 2rem; }
  @media all and (max-width: 750px) {
    /* line 335, doc/scss/object/page/_easyKarte.scss */
    .easy-karte__label-box {
      padding: 0; } }

/* line 343, doc/scss/object/page/_easyKarte.scss */
.easy-karte__labels {
  max-width: 1050px;
  margin: auto;
  padding: 2rem 0; }

@media all and (min-width: 751px) {
  /* line 350, doc/scss/object/page/_easyKarte.scss */
  .easy-karte__label-outer {
    border-radius: 15px; } }

@media all and (min-width: 751px) {
  /* line 85, doc/scss/foundation/_mixin.scss */
  .ek__label-outer1 {
    background: #ECF5FF;
    border: solid 1px #4A90E2; } }

/* line 366, doc/scss/object/page/_easyKarte.scss */
.ek__label-txt1 h4 {
  color: #4A90E2; }
  @media all and (min-width: 751px) {
    /* line 366, doc/scss/object/page/_easyKarte.scss */
    .ek__label-txt1 h4 {
      border-top: 1px solid #CDE3FC;
      padding-top: 1.5rem; } }

@media all and (min-width: 751px) {
  /* line 85, doc/scss/foundation/_mixin.scss */
  .ek__label-outer2 {
    background: #FFF8ED;
    border: solid 1px #F5A623; } }

/* line 382, doc/scss/object/page/_easyKarte.scss */
.ek__label-txt2 h4 {
  color: #F5A623; }
  @media all and (min-width: 751px) {
    /* line 382, doc/scss/object/page/_easyKarte.scss */
    .ek__label-txt2 h4 {
      border-top: 1px solid #f9e3c2;
      padding-top: 1.5rem; } }

/* line 389, doc/scss/object/page/_easyKarte.scss */
.ek__label-txt2 h4::before {
  background: #F5A623; }

@media all and (min-width: 751px) {
  /* line 85, doc/scss/foundation/_mixin.scss */
  .ek__label-outer3 {
    background: #EEF9E2;
    border: solid 1px #7ED321; } }

/* line 401, doc/scss/object/page/_easyKarte.scss */
.ek__label-txt3 h4 {
  color: #7ED321; }
  @media all and (min-width: 751px) {
    /* line 401, doc/scss/object/page/_easyKarte.scss */
    .ek__label-txt3 h4 {
      border-top: 1px solid #d4efab;
      padding-top: 1.5rem; } }

/* line 408, doc/scss/object/page/_easyKarte.scss */
.ek__label-txt3 h4::before {
  background: #7ED321; }

@media all and (min-width: 751px) {
  /* line 85, doc/scss/foundation/_mixin.scss */
  .ek__label-outer4 {
    background: #FFF3F5;
    border: solid 1px #FC819C; } }

/* line 421, doc/scss/object/page/_easyKarte.scss */
.ek__label-txt4 h4 {
  color: #FC819C; }
  @media all and (min-width: 751px) {
    /* line 421, doc/scss/object/page/_easyKarte.scss */
    .ek__label-txt4 h4 {
      border-top: 1px solid #FCDEE4;
      padding-top: 1.5rem; } }

/* line 428, doc/scss/object/page/_easyKarte.scss */
.ek__label-txt4 h4::before {
  background: #FC819C; }

/* line 437, doc/scss/object/page/_easyKarte.scss */
.easy-karte__functions {
  max-width: 1200px;
  margin: auto; }

@media all and (max-width: 750px) {
  /* line 443, doc/scss/object/page/_easyKarte.scss */
  .easy-karte__function {
    padding: 2rem 0; } }

@media all and (min-width: 751px) {
  /* line 450, doc/scss/object/page/_easyKarte.scss */
  .func__label-outer {
    justify-content: center;
    padding: 2rem 0; } }

/* line 457, doc/scss/object/page/_easyKarte.scss */
.functions__heading-txt {
  text-align: center; }

/* line 460, doc/scss/object/page/_easyKarte.scss */
.func-heading-txt {
  display: inline-block;
  font-size: 2rem;
  line-height: 1.5;
  padding: 2rem 0 3rem 3.5rem;
  text-align: left;
  text-indent: -3.5rem; }
  @media all and (max-width: 750px) {
    /* line 460, doc/scss/object/page/_easyKarte.scss */
    .func-heading-txt {
      padding: 2rem 2rem 0 5.5rem; } }

/* line 472, doc/scss/object/page/_easyKarte.scss */
.func-heading-txt::before {
  content: '';
  display: inline-block;
  width: 2.8rem;
  height: 2.8rem;
  background: transparent url(../img/easy-karte/e_logo.png) center center no-repeat;
  background-size: contain;
  margin: -0.5rem 0.5rem; }

@media all and (min-width: 751px) {
  /* line 484, doc/scss/object/page/_easyKarte.scss */
  .func__label {
    display: none; }
  /* line 487, doc/scss/object/page/_easyKarte.scss */
  .func__inner-header {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.3;
    border-bottom: 1px solid #979797;
    padding-bottom: 1rem;
    position: relative; }
  /* line 495, doc/scss/object/page/_easyKarte.scss */
  .func__inner-header::before {
    content: '';
    border-bottom: 5px solid #4990E2;
    width: 200px;
    height: 0;
    position: absolute;
    top: 3.1rem; }
  /* line 504, doc/scss/object/page/_easyKarte.scss */
  .func__inner-txt {
    padding-top: 2rem; }
  /* line 508, doc/scss/object/page/_easyKarte.scss */
  .func__label-txt {
    display: flex;
    flex-direction: row;
    justify-content: center; }
  /* line 513, doc/scss/object/page/_easyKarte.scss */
  .func__label-txt__inner {
    order: 2;
    padding: 1.5rem;
    width: 55%; } }

@media all and (max-width: 750px) {
  /* line 521, doc/scss/object/page/_easyKarte.scss */
  .func__inner-header {
    display: none; } }

/* line 527, doc/scss/object/page/_easyKarte.scss */
.easy-karte__func-img-sp {
  height: 197px;
  background: transparent url(../img/easy-karte/easyKarte__function01.png) center center no-repeat;
  background-size: contain;
  width: 350px;
  margin: 1.5rem;
  order: 1; }
  @media all and (max-width: 750px) {
    /* line 527, doc/scss/object/page/_easyKarte.scss */
    .easy-karte__func-img-sp {
      width: 100%;
      margin: 1rem 0; } }

/* line 539, doc/scss/object/page/_easyKarte.scss */
.ek__func-img-sp2 {
  background: transparent url(../img/easy-karte/easyKarte__function02.png) center center no-repeat;
  background-size: contain;
  height: 260px; }

/* line 544, doc/scss/object/page/_easyKarte.scss */
.ek__func-img-sp3 {
  background: transparent url(../img/easy-karte/easyKarte__function03.png) center center no-repeat;
  background-size: contain;
  height: 207px; }

/* line 549, doc/scss/object/page/_easyKarte.scss */
.ek__func-img-sp4 {
  background: transparent url(../img/easy-karte/easyKarte__function04.png) center center no-repeat;
  background-size: contain;
  height: 220px; }

/* line 554, doc/scss/object/page/_easyKarte.scss */
.ek__func-img-sp5 {
  background: transparent url(../img/easy-karte/easyKarte__function05.png) center center no-repeat;
  background-size: contain;
  height: 230px; }

/* line 559, doc/scss/object/page/_easyKarte.scss */
.ek__func-img-sp6 {
  background: transparent url(../img/easy-karte/easyKarte__function06.png) center center no-repeat;
  background-size: contain;
  height: 212px; }

/* line 564, doc/scss/object/page/_easyKarte.scss */
.ek__func-img-sp7 {
  background: transparent url(../img/easy-karte/easyKarte__function07.png) center center no-repeat;
  background-size: contain;
  height: 167px; }

/* line 569, doc/scss/object/page/_easyKarte.scss */
.ek__func-img-sp8 {
  background: transparent url(../img/easy-karte/easyKarte__function08.png) center center no-repeat;
  background-size: contain;
  height: 267px; }

/* line 574, doc/scss/object/page/_easyKarte.scss */
.ek__func-img-sp9 {
  background: transparent url(../img/easy-karte/easyKarte__function09.png) center center no-repeat;
  background-size: contain;
  height: 300px; }

/* line 582, doc/scss/object/page/_easyKarte.scss */
.point-txt {
  color: #FB6363; }

/* line 591, doc/scss/object/page/_easyKarte.scss */
.introduction__dr-interview {
  display: flex;
  max-width: 1200px;
  margin: auto;
  padding: 0 8rem; }
  @media all and (max-width: 750px) {
    /* line 591, doc/scss/object/page/_easyKarte.scss */
    .introduction__dr-interview {
      display: block;
      margin: 2rem 1rem;
      padding: 0 1rem; } }

/* line 603, doc/scss/object/page/_easyKarte.scss */
.introduction__dr-img {
  position: relative; }
  @media all and (max-width: 750px) {
    /* line 603, doc/scss/object/page/_easyKarte.scss */
    .introduction__dr-img {
      width: 40%; } }

/* line 611, doc/scss/object/page/_easyKarte.scss */
.introduction__dr-heading a {
  background-color: #4990E2;
  color: #fff;
  font-size: 1.5rem;
  width: 100%;
  padding: 0.5rem 1.5rem;
  margin-top: 1rem;
  text-align: center; }
  @media all and (max-width: 750px) {
    /* line 611, doc/scss/object/page/_easyKarte.scss */
    .introduction__dr-heading a {
      font-size: 1.2rem;
      padding: 0.4rem 1rem;
      margin: 0; } }

/* line 627, doc/scss/object/page/_easyKarte.scss */
.introduction__dr {
  width: 33.333%;
  margin: 2rem 4rem; }
  @media all and (max-width: 750px) {
    /* line 627, doc/scss/object/page/_easyKarte.scss */
    .introduction__dr {
      display: flex;
      width: 100%;
      margin: 2rem 1rem; } }

/* line 637, doc/scss/object/page/_easyKarte.scss */
.dr-txt__interview {
  color: #003d84;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2.5rem;
  margin: 1rem 0; }
  @media all and (max-width: 750px) {
    /* line 637, doc/scss/object/page/_easyKarte.scss */
    .dr-txt__interview {
      font-size: 1.4rem;
      line-height: 2.2rem;
      height: auto; } }

@media all and (max-width: 750px) {
  /* line 650, doc/scss/object/page/_easyKarte.scss */
  .introduction__dr-txt {
    width: 60%;
    margin: 0 1rem 0 1.5rem; } }

@media all and (max-width: 750px) {
  /* line 657, doc/scss/object/page/_easyKarte.scss */
  .dr-txt__interview br {
    display: none; } }

/* line 663, doc/scss/object/page/_easyKarte.scss */
.dr-txt__location {
  font-size: 1.4rem;
  line-height: 2rem; }
  @media all and (max-width: 750px) {
    /* line 663, doc/scss/object/page/_easyKarte.scss */
    .dr-txt__location {
      font-size: 1.2rem;
      line-height: 1.5rem; } }

/* line 673, doc/scss/object/page/_easyKarte.scss */
.interview_new::after {
  content: 'NEW';
  background-color: red;
  color: #fff;
  font-size: 1.2rem;
  padding: 0.3rem; }

/* line 684, doc/scss/object/page/_easyKarte.scss */
.easy-karte__introduction {
  max-width: 1200px;
  margin: auto;
  padding: 1rem 0 2rem 0; }

@media all and (min-width: 751px) {
  /* line 692, doc/scss/object/page/_easyKarte.scss */
  .intro__label {
    color: #4990E2;
    display: block;
    margin-bottom: 1rem;
    padding: 1rem 0 0 1rem; }
  /* line 704, doc/scss/object/page/_easyKarte.scss */
  .intro__label::before {
    display: inline-block;
    content: '';
    height: 1.5rem;
    width: 1.5rem;
    background-color: #4990E2;
    border-radius: 50%; } }

/* line 720, doc/scss/object/page/_easyKarte.scss */
.easy-karte__label-table-txt {
  border-bottom: 1px solid #979797;
  font-size: 1.5rem;
  line-height: 1.8rem;
  margin: 2rem 0 3rem 1rem;
  text-align: left;
  width: 98%; }
  @media all and (max-width: 750px) {
    /* line 720, doc/scss/object/page/_easyKarte.scss */
    .easy-karte__label-table-txt {
      border-top: 1px solid #979797;
      margin: 0;
      width: 100%; } }

/* line 737, doc/scss/object/page/_easyKarte.scss */
.easy-karte__label-table-txt th:nth-child(1), .easy-karte__label-table-txt td:nth-child(1) {
  width: 28%; }
  @media all and (max-width: 750px) {
    /* line 737, doc/scss/object/page/_easyKarte.scss */
    .easy-karte__label-table-txt th:nth-child(1), .easy-karte__label-table-txt td:nth-child(1) {
      width: 55%; } }

/* line 745, doc/scss/object/page/_easyKarte.scss */
.easy-karte__label-table-txt th:nth-child(2), .easy-karte__label-table-txt td:nth-child(2) {
  width: 33%; }
  @media all and (max-width: 750px) {
    /* line 745, doc/scss/object/page/_easyKarte.scss */
    .easy-karte__label-table-txt th:nth-child(2), .easy-karte__label-table-txt td:nth-child(2) {
      display: none; } }

/* line 753, doc/scss/object/page/_easyKarte.scss */
.easy-karte__label-table-txt th:nth-child(3), .easy-karte__label-table-txt td:nth-child(3) {
  width: 17%;
  text-align: center; }
  @media all and (max-width: 750px) {
    /* line 753, doc/scss/object/page/_easyKarte.scss */
    .easy-karte__label-table-txt th:nth-child(3), .easy-karte__label-table-txt td:nth-child(3) {
      width: 20%; } }

/* line 762, doc/scss/object/page/_easyKarte.scss */
.easy-karte__label-table-txt th:nth-child(4), .easy-karte__label-table-txt td:nth-child(4) {
  width: 12%;
  text-align: center; }
  @media all and (max-width: 750px) {
    /* line 762, doc/scss/object/page/_easyKarte.scss */
    .easy-karte__label-table-txt th:nth-child(4), .easy-karte__label-table-txt td:nth-child(4) {
      width: 12%; } }

/* line 771, doc/scss/object/page/_easyKarte.scss */
.easy-karte__label-table-txt th:nth-child(5), .easy-karte__label-table-txt td:nth-child(5) {
  width: 8%;
  text-align: center; }
  @media all and (max-width: 750px) {
    /* line 771, doc/scss/object/page/_easyKarte.scss */
    .easy-karte__label-table-txt th:nth-child(5), .easy-karte__label-table-txt td:nth-child(5) {
      display: none; } }

/* line 782, doc/scss/object/page/_easyKarte.scss */
.easy-karte__label-table-txt th {
  font-weight: bold;
  padding: 1rem 0;
  border-bottom: 1px solid #979797; }
  @media all and (max-width: 750px) {
    /* line 782, doc/scss/object/page/_easyKarte.scss */
    .easy-karte__label-table-txt th {
      vertical-align: middle; } }

/* line 791, doc/scss/object/page/_easyKarte.scss */
.easy-karte__label-table-txt tr:nth-child(odd) {
  background-color: #f3f3f3; }

/* line 795, doc/scss/object/page/_easyKarte.scss */
.easy-karte__label-table-txt tr:first-child {
  background-color: #E1ECFA; }

/* line 800, doc/scss/object/page/_easyKarte.scss */
.easy-karte__label-table-txt td {
  padding: 0.8rem 0;
  vertical-align: middle; }
  @media all and (max-width: 750px) {
    /* line 800, doc/scss/object/page/_easyKarte.scss */
    .easy-karte__label-table-txt td {
      padding: 1rem 0; } }

/* line 808, doc/scss/object/page/_easyKarte.scss */
.easy-karte__label-table-txt td:first-child, th:first-child {
  padding-left: 2rem; }

/* line 813, doc/scss/object/page/_easyKarte.scss */
.medical_btn {
  background-color: #dadada;
  width: 108px;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  margin: auto; }
  @media all and (max-width: 750px) {
    /* line 813, doc/scss/object/page/_easyKarte.scss */
    .medical_btn {
      text-align: center;
      padding: 0.8rem 0.5rem;
      width: 85px; } }

/* line 826, doc/scss/object/page/_easyKarte.scss */
.intro_btn {
  background-color: #cce1fa;
  width: 70px;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  margin: auto; }
  @media all and (max-width: 750px) {
    /* line 826, doc/scss/object/page/_easyKarte.scss */
    .intro_btn {
      padding: 0.8rem 0.5rem;
      text-align: center;
      width: 44px; } }

@media all and (min-width: 751px) {
  /* line 840, doc/scss/object/page/_easyKarte.scss */
  .easy-karte__label-table-txt br {
    display: none; } }

@media all and (max-width: 750px) {
  /* line 848, doc/scss/object/page/_easyKarte.scss */
  .easy-karte__intro__label-txt {
    height: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
    opacity: 0;
    transition: 0.8s; } }

@media all and (max-width: 750px) {
  /* line 859, doc/scss/object/page/_easyKarte.scss */
  .easy-karte__label:checked + label + .easy-karte__intro__label-txt {
    height: auto;
    opacity: 2; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.g24hfm__header-nav1 {
  background-color: #D3F3EC; }

/* line 85, doc/scss/foundation/_mixin.scss */
.g24hfm__header-nav2 {
  background-color: #1EB392; }

/* line 85, doc/scss/foundation/_mixin.scss */
.g24hfm__active {
  background: #95DFCF; }

/* line 85, doc/scss/foundation/_mixin.scss */
.g24hfm__product {
  display: flex;
  justify-content: center;
  margin: auto;
  padding: 0 15px;
  max-width: 1140px; }
  @media all and (max-width: 750px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .g24hfm__product {
      display: block;
      padding: 0 15px;
      width: 100%; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.g24hfm__product1 {
  display: flex; }

/* line 85, doc/scss/foundation/_mixin.scss */
.g24hfm__product2 {
  display: flex; }

/* line 85, doc/scss/foundation/_mixin.scss */
.g24hfm__product-conts {
  margin: 0 0.8rem 2rem 0.8rem;
  text-align: center; }
  @media all and (max-width: 750px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .g24hfm__product-conts {
      margin: 0 0.5rem;
      width: 100%; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.g24hfm__product-image {
  display: flex;
  justify-content: center; }
  @media all and (max-width: 750px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .g24hfm__product-image {
      padding: 1rem 0; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.g24hfm__product-img {
  width: 8rem;
  height: 8rem;
  margin-right: 1.5rem; }
  @media all and (max-width: 750px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .g24hfm__product-img {
      width: 5rem;
      height: 5rem;
      margin-right: 0.5rem; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.g24hfm__product-txt {
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.3;
  margin-top: 0.8rem; }
  @media all and (max-width: 750px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .g24hfm__product-txt {
      font-size: 1.4rem;
      margin-top: 0.6em; } }

@media all and (max-width: 750px) {
  /* line 97, doc/scss/object/page/_24hfm.scss */
  .g24hfm__labels {
    margin: 1rem 0; } }

@media all and (min-width: 751px) {
  /* line 104, doc/scss/object/page/_24hfm.scss */
  .g24hfm__label-outer {
    background: #fff;
    border-radius: 15px;
    margin: auto;
    max-width: 1020px; } }

/* line 114, doc/scss/object/page/_24hfm.scss */
.g24hfm__label-inner {
  width: 100%;
  border-radius: 15px;
  padding: 2rem 6rem; }
  @media all and (max-width: 750px) {
    /* line 114, doc/scss/object/page/_24hfm.scss */
    .g24hfm__label-inner {
      background: #fff;
      border-radius: 0;
      width: 100%;
      padding: 0;
      margin: 0; } }

/* line 126, doc/scss/object/page/_24hfm.scss */
.fm__label-inner6 {
  width: 100%; }

/* line 131, doc/scss/object/page/_24hfm.scss */
.g24hfm__label-box {
  padding: 2rem 2rem;
  margin: 1.5rem auto;
  max-width: 1200px; }
  @media all and (max-width: 750px) {
    /* line 131, doc/scss/object/page/_24hfm.scss */
    .g24hfm__label-box {
      background: #fff;
      padding: 0;
      margin: 0; } }

/* line 143, doc/scss/object/page/_24hfm.scss */
.g24hfm__labels {
  width: 100%;
  margin: auto;
  padding: 2rem 0; }

@media all and (max-width: 750px) {
  /* line 153, doc/scss/object/page/_24hfm.scss */
  .fm__label:after {
    background: transparent url(../img/24hfm/button-01.png) center center; }
  /* line 157, doc/scss/object/page/_24hfm.scss */
  .g24hfm__label:checked + .fm__label:after {
    background: transparent url(../img/24hfm/button-02.png) center center; } }

/* line 163, doc/scss/object/page/_24hfm.scss */
.g24hfm__label-txt {
  font-size: 1.5rem;
  line-height: 1.6;
  margin-top: 1rem; }
  @media all and (max-width: 750px) {
    /* line 163, doc/scss/object/page/_24hfm.scss */
    .g24hfm__label-txt {
      height: 0;
      padding: 0;
      margin: 0;
      overflow: hidden;
      opacity: 0;
      transition: 0.8s; } }

/* line 178, doc/scss/object/page/_24hfm.scss */
.g24hfm__label-img {
  background-image: url(../img/24hfm/24hfm__feature01.png);
  height: 120px;
  width: 80%;
  background-size: contain;
  background-position: center center;
  margin: auto; }
  @media all and (max-width: 750px) {
    /* line 178, doc/scss/object/page/_24hfm.scss */
    .g24hfm__label-img {
      width: 100%;
      margin: 2rem 0; } }

@media all and (min-width: 751px) {
  /* line 85, doc/scss/foundation/_mixin.scss */
  .fm__label-img1 {
    margin: -2rem 0 0 0; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.fm__label-img2-1 {
  background-image: url(../img/24hfm/24hfm__feature02-1.png);
  height: 150px; }

/* line 85, doc/scss/foundation/_mixin.scss */
.fm__label-img2-2 {
  background-image: url(../img/24hfm/24hfm__feature02-2.png); }

/* line 85, doc/scss/foundation/_mixin.scss */
.fm__label-img2-3 {
  background-image: url(../img/24hfm/24hfm__feature02-3.png);
  height: 250px; }

/* line 85, doc/scss/foundation/_mixin.scss */
.fm__label-img3-1 {
  background-image: url(../img/24hfm/24hfm__feature03-1.png);
  height: 190px; }
  @media all and (min-width: 751px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .fm__label-img3-1 {
      margin: -2rem 0 0 0; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.fm__label-img3-2 {
  background-image: url(../img/24hfm/24hfm__feature03-2.png);
  height: 110px; }

/* line 85, doc/scss/foundation/_mixin.scss */
.fm__label-img3-3 {
  background-image: url(../img/24hfm/24hfm__feature03-3.png);
  height: 150px; }

/* line 85, doc/scss/foundation/_mixin.scss */
.fm__label-img4-1 {
  background-image: url(../img/24hfm/24hfm__feature04-1.png);
  height: 170px;
  margin-top: 1.5rem; }

/* line 85, doc/scss/foundation/_mixin.scss */
.fm__label-img4-2 {
  background-image: url(../img/24hfm/24hfm__feature04-2.png);
  height: 300px; }
  @media all and (min-width: 751px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .fm__label-img4-2 {
      margin: -2rem 0 0 0; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.fm__label-img5 {
  background-image: url(../img/24hfm/24hfm__feature05.png);
  height: 200px; }

/* line 85, doc/scss/foundation/_mixin.scss */
.fm__label-img6 {
  background-image: url(../img/24hfm/24hfm__feature06.png);
  height: 100px;
  width: 260px;
  background-size: contain;
  padding-right: 2rem;
  margin-top: 0; }

/* line 85, doc/scss/foundation/_mixin.scss */
.fm__label-img7 {
  background-image: url(../img/24hfm/24hfm__feature07.png);
  height: 100px;
  width: 320px;
  background-size: contain;
  padding-right: 2rem;
  margin-top: 0; }

/* line 85, doc/scss/foundation/_mixin.scss */
.fm__label-img8 {
  background-image: url(../img/24hfm/24hfm__feature08.png);
  background-size: contain;
  height: 130px;
  margin-top: 2rem; }

@media all and (min-width: 751px) {
  /* line 263, doc/scss/object/page/_24hfm.scss */
  .g24hfm__label-txt__outer {
    display: flex; }
  /* line 266, doc/scss/object/page/_24hfm.scss */
  .g24hfm__label-txt__inner {
    padding-right: 2rem;
    width: 100%; } }

/* line 273, doc/scss/object/page/_24hfm.scss */
.g24hfm__label-txt p {
  padding-left: 3.5rem; }
  @media all and (max-width: 750px) {
    /* line 273, doc/scss/object/page/_24hfm.scss */
    .g24hfm__label-txt p {
      padding-left: 0; } }

/* line 281, doc/scss/object/page/_24hfm.scss */
.g24hfm__label-txt h4 {
  margin: 1rem 0.8rem 0;
  font-weight: bold; }

/* line 287, doc/scss/object/page/_24hfm.scss */
.g24hfm__label-txt h4::before {
  display: inline-block;
  vertical-align: middle;
  content: ' ';
  width: 1em;
  height: 1em;
  background: #4a90e2;
  border-radius: 50%;
  margin-right: 15px; }

@media all and (min-width: 751px) {
  /* line 298, doc/scss/object/page/_24hfm.scss */
  .fm__label-txt1 p::before {
    display: inline-block;
    vertical-align: middle;
    content: ' ';
    width: 1em;
    height: 1em;
    background: #0DB38E;
    border-radius: 50%;
    margin-left: -28px;
    margin-right: 13px; }
  /* line 309, doc/scss/object/page/_24hfm.scss */
  .fm__label-txt4 p::before {
    display: inline-block;
    vertical-align: middle;
    content: ' ';
    width: 1em;
    height: 1em;
    background: #7ED321;
    border-radius: 50%;
    margin-left: -28px;
    margin-right: 13px; }
  /* line 320, doc/scss/object/page/_24hfm.scss */
  .fm__label-txt5 p::before {
    display: inline-block;
    vertical-align: middle;
    content: ' ';
    width: 1em;
    height: 1em;
    background: #FF7584;
    border-radius: 50%;
    margin-left: -28px;
    margin-right: 13px; } }

/* line 337, doc/scss/object/page/_24hfm.scss */
.fm__label-txt6 h4 {
  margin: 0.5rem 0; }

/* line 343, doc/scss/object/page/_24hfm.scss */
.fm__label-txt6 h4::before {
  content: none; }

/* line 349, doc/scss/object/page/_24hfm.scss */
.fm__label-txt6 p {
  padding-left: 0; }

@media all and (min-width: 751px) {
  /* line 85, doc/scss/foundation/_mixin.scss */
  .fm__label-txt6-1 {
    margin-right: 2rem; } }

@media all and (min-width: 751px) {
  /* line 85, doc/scss/foundation/_mixin.scss */
  .fm__label-txt6-outer {
    display: flex; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.fm__label-txt6-inner {
  display: flex;
  padding: 1.5rem; }
  @media all and (min-width: 751px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .fm__label-txt6-inner {
      width: 50%; } }

@media all and (max-width: 750px) {
  /* line 375, doc/scss/object/page/_24hfm.scss */
  .g24hfm__label:checked + label + .g24hfm__label-txt {
    height: auto;
    font-size: 1.4rem;
    line-height: 1.8;
    padding: 1rem 2rem;
    opacity: 1; }
  /* line 383, doc/scss/object/page/_24hfm.scss */
  .g24hfm__label:checked + label + .g24hfm__label-txt + .g24hfm__label-img-sp {
    background-image: url(../img/24hfm/24hfm__feature01.png);
    height: 200px;
    background-size: contain;
    background-position: center center;
    margin: 0 2rem; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.fm__label-box1 {
  background: #B4DDD4; }

/* line 85, doc/scss/foundation/_mixin.scss */
.fm__label-box2 {
  background: #CDE3FC; }

/* line 85, doc/scss/foundation/_mixin.scss */
.fm__label-box3 {
  background: #ffecce; }

/* line 85, doc/scss/foundation/_mixin.scss */
.fm__label-box4 {
  background: #e3f4d0; }

/* line 85, doc/scss/foundation/_mixin.scss */
.fm__label-box5 {
  background: #FFE6E0; }

/* line 85, doc/scss/foundation/_mixin.scss */
.fm__label-box6 {
  background: #B3DCD3; }

@media all and (min-width: 751px) {
  /* line 85, doc/scss/foundation/_mixin.scss */
  .fm__label1 {
    color: #0DB38E; }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .fm__label2 {
    color: #4990E2; }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .fm__label3 {
    color: #F5A623; }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .fm__label4 {
    color: #7ED321; }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .fm__label5 {
    color: #ff687a; }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .fm__label6 {
    color: #0DB38E; } }

/* line 441, doc/scss/object/page/_24hfm.scss */
.fm__label-txt2 h4 {
  color: #4990E2; }
  @media all and (min-width: 751px) {
    /* line 441, doc/scss/object/page/_24hfm.scss */
    .fm__label-txt2 h4 {
      border-top: 1px solid #CDE3FC;
      padding: 1.5rem 0 1rem 0; } }

/* line 448, doc/scss/object/page/_24hfm.scss */
.fm__label-txt2 h4::before {
  background: #4990E2; }

/* line 454, doc/scss/object/page/_24hfm.scss */
.fm__label-txt3 h4 {
  color: #F5A623; }
  @media all and (min-width: 751px) {
    /* line 454, doc/scss/object/page/_24hfm.scss */
    .fm__label-txt3 h4 {
      border-top: 1px solid #f9e3c2;
      padding: 1.5rem 0 1rem 0; } }

/* line 461, doc/scss/object/page/_24hfm.scss */
.fm__label-txt3 h4::before {
  background: #F5A623; }

@media all and (min-width: 751px) {
  /* line 1, doc/scss/object/page/_corporate-access.scss */
  .corporate-access-container {
    padding-left: 30px; } }

@media all and (max-width: 750px) {
  /* line 7, doc/scss/object/page/_corporate-access.scss */
  .corporate-access {
    padding: 25px 0 30px;
    border-bottom: 1px solid #4990E2; } }

@media all and (min-width: 751px) {
  /* line 7, doc/scss/object/page/_corporate-access.scss */
  .corporate-access {
    margin-bottom: 60px; } }

/* line 17, doc/scss/object/page/_corporate-access.scss */
.corporate-access:last-child {
  border: 0; }

/* line 85, doc/scss/foundation/_mixin.scss */
.corporate-access__heading {
  margin-bottom: 12px;
  font-size: 1.5rem;
  line-height: 1; }
  @media all and (min-width: 751px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .corporate-access__heading {
      font-size: 1.9rem; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.corporate-access__image {
  width: 200px;
  flex: 0 0 200px; }

/* line 85, doc/scss/foundation/_mixin.scss */
.corporate-access__detail {
  margin-bottom: 5px;
  line-height: 1.5; }
  @media all and (max-width: 750px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .corporate-access__detail {
      font-size: 1.2rem; } }
  @media all and (min-width: 751px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .corporate-access__detail {
      width: calc(100% - 200px);
      flex: 0 0 calc(100% - 200px);
      padding-left: 30px; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.corporate-access__detail2 {
  margin-bottom: 5px;
  line-height: 1.5; }
  @media all and (max-width: 750px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .corporate-access__detail2 {
      font-size: 1.2rem; } }
  @media all and (min-width: 751px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .corporate-access__detail2 {
      width: calc(100% - 200px);
      flex: 0 0 calc(100% - 200px); } }

/* line 85, doc/scss/foundation/_mixin.scss */
.corporate-access__text {
  margin-bottom: 15px; }

/* line 85, doc/scss/foundation/_mixin.scss */
.corporate-access__link {
  display: inline-block;
  width: auto;
  height: auto;
  color: #4990E2;
  line-height: 1; }

/* line 85, doc/scss/foundation/_mixin.scss */
.corporate-access__map {
  width: 100%;
  height: 300px; }
  @media all and (min-width: 751px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .corporate-access__map {
      max-width: 600px;
      height: 400px;
      margin: 15px 0 50px; } }

/* line 1, doc/scss/object/page/_corporate-article.scss */
.corporate-article-container {
  padding-left: 10px;
  line-height: 1.8; }
  @media all and (min-width: 751px) {
    /* line 1, doc/scss/object/page/_corporate-article.scss */
    .corporate-article-container {
      font-size: 1.5rem; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.corporate-article__heading {
  position: relative;
  min-height: 50px;
  margin-bottom: 15px;
  padding-left: 15px;
  font-size: 1.8rem;
  line-height: 1.4; }
  @media all and (min-width: 751px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .corporate-article__heading {
      min-height: 50px;
      padding-left: 20px;
      font-size: 2rem;
      line-height: 1.2; } }
  /* line 26, doc/scss/object/page/_corporate-article.scss */
  .corporate-article__heading::before {
    content: " ";
    position: absolute;
    left: 0;
    width: 6px;
    height: 110%;
    height: calc(100% + 10px);
    background: #4990E2;
    border-radius: 3px;
    vertical-align: top; }

/* line 85, doc/scss/foundation/_mixin.scss */
.corporate-article__date {
  display: block;
  margin-bottom: 20px;
  padding-left: 10px; }
  @media all and (min-width: 751px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .corporate-article__date {
      margin-bottom: 30px; } }

/* line 1, doc/scss/object/page/_corporate-news.scss */
.corporate-news-container {
  padding-left: 60px; }

/* line 5, doc/scss/object/page/_corporate-news.scss */
.corporate-news {
  margin-bottom: 15px; }
  @media all and (min-width: 751px) {
    /* line 5, doc/scss/object/page/_corporate-news.scss */
    .corporate-news {
      margin-bottom: 25px; } }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .corporate-news__date {
    white-space: nowrap; }
    @media all and (max-width: 750px) {
      /* line 85, doc/scss/foundation/_mixin.scss */
      .corporate-news__date {
        width: 100px;
        flex: 0 0 100px;
        margin-bottom: 4px; } }
    @media all and (min-width: 751px) {
      /* line 85, doc/scss/foundation/_mixin.scss */
      .corporate-news__date {
        width: 130px;
        flex: 0 0 130px; } }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .corporate-news__heading {
    position: relative;
    padding-left: 15px;
    font-size: 1.2rem; }
    @media all and (max-width: 750px) {
      /* line 85, doc/scss/foundation/_mixin.scss */
      .corporate-news__heading {
        display: inline-block;
        width: calc(100% - 100px);
        flex: 0 0 calc(100% - 100px); } }
    @media all and (min-width: 751px) {
      /* line 85, doc/scss/foundation/_mixin.scss */
      .corporate-news__heading {
        width: calc(100% - 130px);
        flex: 0 0 calc(100% - 130px);
        padding-left: 20px;
        font-size: 1.5rem; } }
    /* line 48, doc/scss/object/page/_corporate-news.scss */
    .corporate-news__heading::before {
      content: '';
      position: absolute;
      top: 6px;
      left: 0;
      width: 6px;
      height: 6px;
      border: 0px;
      border-top: solid 2px #4990E2;
      border-right: solid 2px #4990E2;
      transform: rotate(45deg); }
      @media all and (min-width: 751px) {
        /* line 48, doc/scss/object/page/_corporate-news.scss */
        .corporate-news__heading::before {
          top: 8px;
          width: 8px;
          height: 8px;
          border-top: solid 2px #4990E2;
          border-right: solid 2px #4990E2; } }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .corporate-news__link {
    display: inline-block;
    width: auto; }

/* line 1, doc/scss/object/page/_isms.scss */
.isms-contents-container {
  max-width: 800px;
  margin: auto;
  padding: 0; }

/* line 7, doc/scss/object/page/_isms.scss */
.isms-contents {
  margin-bottom: 30px; }
  @media all and (max-width: 750px) {
    /* line 7, doc/scss/object/page/_isms.scss */
    .isms-contents {
      margin: 30px auto 40px;
      padding: 0 15px; } }
  @media all and (min-width: 751px) {
    /* line 7, doc/scss/object/page/_isms.scss */
    .isms-contents {
      margin: 80px auto 60px;
      padding: 0 50px; } }

/* line 21, doc/scss/object/page/_isms.scss */
.isms-content-container {
  padding-left: 15px;
  font-size: 1.2rem;
  line-height: 1.6; }
  @media all and (max-width: 750px) {
    /* line 21, doc/scss/object/page/_isms.scss */
    .isms-content-container {
      margin-top: 10px; } }
  @media all and (min-width: 751px) {
    /* line 21, doc/scss/object/page/_isms.scss */
    .isms-content-container {
      padding-left: 80px;
      font-size: 1.5rem;
      line-height: 1.8; } }

/* line 37, doc/scss/object/page/_isms.scss */
.isms-content {
  margin-bottom: 60px; }
  @media all and (min-width: 751px) {
    /* line 37, doc/scss/object/page/_isms.scss */
    .isms-content {
      margin-bottom: 100px; } }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .isms-content__heading {
    position: relative;
    font-size: 1.6rem;
    margin-bottom: 30px;
    padding: 0 0 8px;
    border-bottom: 1px solid #979797;
    line-height: 1; }
    @media all and (min-width: 751px) {
      /* line 85, doc/scss/foundation/_mixin.scss */
      .isms-content__heading {
        width: 100%;
        font-size: 2rem;
        margin-bottom: 50px; } }
    /* line 59, doc/scss/object/page/_isms.scss */
    .isms-content__heading::before {
      content: " ";
      position: absolute;
      bottom: -3px;
      width: 130px;
      height: 0;
      border-bottom: 5px solid #4990E2; }
      @media all and (min-width: 751px) {
        /* line 59, doc/scss/object/page/_isms.scss */
        .isms-content__heading::before {
          width: 200px; } }
    @media all and (max-width: 750px) {
      /* line 126, doc/scss/foundation/_mixin.scss */
      .isms-content__heading--sp-arrow {
        font-size: 1.9rem;
        margin: 4px 0 0;
        padding: 0 10px;
        border: 0; } }
    @media all and (max-width: 750px) {
      /* line 82, doc/scss/object/page/_isms.scss */
      .isms-content__heading--sp-arrow::before {
        position: relative;
        display: inline-block;
        bottom: 0;
        width: 4px;
        height: 18px;
        margin-right: 7px;
        border-radius: 2px;
        background: #4990E2;
        vertical-align: top; } }
  @media all and (max-width: 750px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .isms-content__link {
      display: inline-block;
      width: auto; } }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .isms-content__button {
    position: relative;
    width: 26px;
    height: 26px;
    background: #D8D8D8;
    border-radius: 7px; }
    /* line 113, doc/scss/object/page/_isms.scss */
    .isms-content__button::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 8px;
      height: 8px;
      margin-top: -3px;
      margin-left: -3px;
      border: 0px;
      border-top: solid 2px #fff;
      border-right: solid 2px #fff;
      opacity: 1;
      transform: rotate(-135deg); }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .isms-content__list {
    list-style-type: decimal; }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .isms-content__center-image {
    max-width: 470px;
    margin: 0 auto 20px;
    padding: 0 40px; }
    @media all and (min-width: 751px) {
      /* line 85, doc/scss/foundation/_mixin.scss */
      .isms-content__center-image {
        max-width: 500px;
        margin: 120px auto;
        padding: 0 20px; } }

@media all and (min-width: 751px) {
  /* line 6, doc/scss/object/page/_top.scss */
  .hero-images {
    margin-bottom: 30px; } }

/* line 11, doc/scss/object/page/_top.scss */
.hero-images .slick-dotted.slick-slider {
  margin-bottom: 0; }

/* line 16, doc/scss/object/page/_top.scss */
.hero-images .slick-arrow {
  width: 20px;
  height: 40px;
  background: rgba(255, 255, 255, 0.74); }
  @media all and (max-width: 750px) {
    /* line 16, doc/scss/object/page/_top.scss */
    .hero-images .slick-arrow {
      top: auto;
      bottom: -20px; } }
  /* line 27, doc/scss/object/page/_top.scss */
  .hero-images .slick-arrow::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 8px;
    height: 8px;
    margin-top: -3px;
    border: 0px;
    border-top: solid 2px #4A4A4A;
    border-right: solid 2px #4A4A4A;
    opacity: 1;
    transform: rotate(45deg); }

/* line 44, doc/scss/object/page/_top.scss */
.hero-images .slick-prev {
  left: 0;
  z-index: 1; }
  @media all and (min-width: 751px) {
    /* line 44, doc/scss/object/page/_top.scss */
    .hero-images .slick-prev {
      left: -0.5px; } }
  /* line 53, doc/scss/object/page/_top.scss */
  .hero-images .slick-prev::before {
    left: 8px;
    transform: rotate(-135deg); }

/* line 60, doc/scss/object/page/_top.scss */
.hero-images .slick-next {
  right: 0;
  z-index: 1; }
  /* line 65, doc/scss/object/page/_top.scss */
  .hero-images .slick-next::before {
    left: 4px; }

/* line 71, doc/scss/object/page/_top.scss */
.hero-images .slick-list {
  margin: auto -10px; }
  @media all and (min-width: 751px) {
    /* line 71, doc/scss/object/page/_top.scss */
    .hero-images .slick-list {
      margin: auto -15px; } }

/* line 81, doc/scss/object/page/_top.scss */
.hero-images .slick-slider {
  overflow: hidden; }

/* line 85, doc/scss/object/page/_top.scss */
.hero-images .slick-list {
  position: relative;
  left: -1px; }

/* line 91, doc/scss/object/page/_top.scss */
.hero-images .slick-dots {
  bottom: 2px;
  margin-left: -15px; }
  @media all and (min-width: 751px) {
    /* line 91, doc/scss/object/page/_top.scss */
    .hero-images .slick-dots {
      bottom: 20px; } }
  /* line 100, doc/scss/object/page/_top.scss */
  .hero-images .slick-dots li {
    width: 10px;
    height: auto;
    margin: 0 2px; }
    @media all and (min-width: 751px) {
      /* line 100, doc/scss/object/page/_top.scss */
      .hero-images .slick-dots li {
        width: 18px; } }
  /* line 111, doc/scss/object/page/_top.scss */
  .hero-images .slick-dots button {
    position: relative;
    width: auto;
    height: auto; }
    /* line 117, doc/scss/object/page/_top.scss */
    .hero-images .slick-dots button::before {
      content: " ";
      width: 6px;
      height: 6px;
      background: #9B9B9B;
      border-radius: 50%;
      opacity: 1; }
      @media all and (min-width: 751px) {
        /* line 117, doc/scss/object/page/_top.scss */
        .hero-images .slick-dots button::before {
          width: 8px;
          height: 8px; } }
  /* line 136, doc/scss/object/page/_top.scss */
  .hero-images .slick-dots li.slick-active button::before {
    background: #4A4A4A; }
  /* line 140, doc/scss/object/page/_top.scss */
  .hero-images .slick-dots li.slick-active button::after {
    position: absolute;
    top: 1px;
    left: 1px;
    content: " ";
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #fff; }
    @media all and (min-width: 751px) {
      /* line 140, doc/scss/object/page/_top.scss */
      .hero-images .slick-dots li.slick-active button::after {
        top: 2px;
        left: 2px;
        width: 4px;
        height: 4px; } }

/* line 162, doc/scss/object/page/_top.scss */
.my-works {
  margin-bottom: 20px; }
  @media all and (min-width: 751px) {
    /* line 162, doc/scss/object/page/_top.scss */
    .my-works {
      margin-bottom: 50px; } }

/* line 170, doc/scss/object/page/_top.scss */
.my-works-container {
  padding: 0 5px; }
  @media all and (min-width: 751px) {
    /* line 170, doc/scss/object/page/_top.scss */
    .my-works-container {
      padding: 0; } }

@media all and (max-width: 750px) {
  /* line 178, doc/scss/object/page/_top.scss */
  .my-work {
    margin-bottom: 15px;
    padding: 0 5px; } }

@media all and (min-width: 751px) {
  /* line 178, doc/scss/object/page/_top.scss */
  .my-work {
    padding: 0 15px; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.my-work__image {
  margin-bottom: 5px; }
  @media all and (min-width: 751px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .my-work__image {
      margin-bottom: 20px; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.my-work__heading {
  margin-bottom: 5px;
  font-size: 1.1rem; }
  @media all and (min-width: 751px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .my-work__heading {
      margin-bottom: 5px;
      font-size: 1.7rem; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.my-work__text {
  margin-bottom: 7px;
  font-size: 0.9rem;
  line-height: 1.2; }
  @media all and (min-width: 751px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .my-work__text {
      margin-bottom: 5px;
      font-size: 1.2rem; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.my-work__link {
  display: inline-block;
  width: auto;
  height: auto;
  color: #4990E2;
  font-size: 0.9rem; }
  @media all and (min-width: 751px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .my-work__link {
      font-size: 1.1rem; } }

@media all and (min-width: 751px) {
  /* line 231, doc/scss/object/page/_top.scss */
  .news {
    margin: 0 auto 50px; } }

/* line 236, doc/scss/object/page/_top.scss */
.news-container {
  padding: 0; }
  @media all and (min-width: 751px) {
    /* line 236, doc/scss/object/page/_top.scss */
    .news-container {
      padding: 0 30px; } }

@media all and (max-width: 750px) {
  /* line 246, doc/scss/object/page/_top.scss */
  .new-informations {
    margin-bottom: 30px; } }

@media all and (min-width: 751px) {
  /* line 246, doc/scss/object/page/_top.scss */
  .new-informations {
    padding: 0 30px; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.new-informations__heading {
  margin-bottom: 15px;
  padding: 10px 0 10px 15px;
  font-size: 1.3rem; }
  @media all and (max-width: 750px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .new-informations__heading {
      height: 30px;
      background: #D8D8D8; } }
  @media all and (min-width: 751px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .new-informations__heading {
      position: relative;
      margin-bottom: 30px;
      padding: 0 0 8px;
      border-bottom: 1px solid #979797;
      font-size: 2rem; } }
  @media all and (min-width: 751px) {
    /* line 274, doc/scss/object/page/_top.scss */
    .new-informations__heading::before {
      content: " ";
      position: absolute;
      bottom: -3px;
      width: 200px;
      height: 0;
      border-bottom: 5px solid #4990E2; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.new-informations__link {
  position: relative;
  display: inline-block;
  height: auto;
  width: auto;
  margin-left: 25px;
  color: #4990E2;
  font-size: 1.1rem; }
  @media all and (min-width: 751px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .new-informations__link {
      margin-left: 20px;
      font-size: 1.4rem; } }
  /* line 302, doc/scss/object/page/_top.scss */
  .new-informations__link::after {
    content: '';
    position: absolute;
    top: 2px;
    right: -10px;
    width: 6px;
    height: 6px;
    border: 0px;
    border-top: solid 2px #4990E2;
    border-right: solid 2px #4990E2;
    transform: rotate(45deg); }
    @media all and (min-width: 751px) {
      /* line 302, doc/scss/object/page/_top.scss */
      .new-informations__link::after {
        width: 8px;
        height: 8px; } }

/* line 323, doc/scss/object/page/_top.scss */
.new-informations-container {
  padding: 0 20px; }

/* line 328, doc/scss/object/page/_top.scss */
.new-information {
  margin-bottom: 15px;
  font-size: 1rem;
  line-height: 1.2; }
  @media all and (min-width: 751px) {
    /* line 328, doc/scss/object/page/_top.scss */
    .new-information {
      margin-bottom: 20px;
      font-size: 1.4rem; } }
  @media all and (max-width: 750px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .new-information__date {
      margin-bottom: 4px; } }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .new-information__heading {
    position: relative;
    padding-left: 15px;
    font-size: 1.2rem; }
    @media all and (max-width: 750px) {
      /* line 85, doc/scss/foundation/_mixin.scss */
      .new-information__heading {
        display: inline-block;
        width: auto; } }
    @media all and (min-width: 751px) {
      /* line 85, doc/scss/foundation/_mixin.scss */
      .new-information__heading {
        padding-left: 20px;
        font-size: 1.5rem; } }
    /* line 362, doc/scss/object/page/_top.scss */
    .new-information__heading::before {
      content: '';
      position: absolute;
      top: 5px;
      left: 0;
      width: 6px;
      height: 6px;
      border: 0px;
      border-top: solid 2px #4990E2;
      border-right: solid 2px #4990E2;
      transform: rotate(45deg); }
      @media all and (min-width: 751px) {
        /* line 362, doc/scss/object/page/_top.scss */
        .new-information__heading::before {
          width: 8px;
          height: 8px;
          border-top: solid 2px #4990E2;
          border-right: solid 2px #4990E2; } }

/* line 384, doc/scss/object/page/_top.scss */
.governances {
  margin-bottom: 60px;
  text-align: right; }
  @media all and (min-width: 751px) {
    /* line 384, doc/scss/object/page/_top.scss */
    .governances {
      margin-bottom: 80px;
      width: 50%;
      /* 20210104 ニュースリリース削除に伴い、上に移動のため*/ } }
  /* line 85, doc/scss/foundation/_mixin.scss */
  .governances__highlight {
    padding: 12px 7px;
    border: 1px solid #4990E2;
    border-radius: 8px;
    width: 88vw;
    /* 20210104 ニュースリリース削除に伴い、上に移動のため*/ }
    @media all and (min-width: 751px) {
      /* line 85, doc/scss/foundation/_mixin.scss */
      .governances__highlight {
        padding: 20px 7px;
        display: inline-block;
        width: 80%;
        /* 20210104 ニュースリリース削除に伴い、上に移動のため*/ } }

/* .governances-container { 210727ニュースリリース復活に伴い削除
  padding: 0 20px;*/
/* @include mq-pc {
    padding: 0 60px;
  } 20210104  ニュースリリース削除に伴い削除*/
/*}*/
@media all and (max-width: 750px) {
  /* line 415, doc/scss/object/page/_top.scss */
  .governance {
    padding: 5px 0; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.governance__image {
  display: inline-block;
  width: 80px;
  height: 53px;
  vertical-align: top; }
  @media all and (min-width: 751px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .governance__image {
      width: 120px;
      height: 80px; } }

/* line 85, doc/scss/foundation/_mixin.scss */
.governance__text {
  display: inline-block;
  width: 60%;
  width: calc(100% - 80px - 10px);
  padding-right: 15px;
  font-size: 1.1rem;
  line-height: 1.2;
  text-align: left; }
  @media all and (min-width: 751px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .governance__text {
      font-size: 1.4rem;
      width: calc(100% - 120px - 10px); } }

/* line 85, doc/scss/foundation/_mixin.scss */
.governance__link {
  display: inline-block;
  width: auto;
  margin-top: 10px;
  color: #4990E2;
  font-size: 1.1rem; }
  @media all and (min-width: 751px) {
    /* line 85, doc/scss/foundation/_mixin.scss */
    .governance__link {
      margin-top: 20px;
      font-size: 1.4rem; } }

@media all and (max-width: 750px) {
  /* line 2, doc/scss/object/utility/_border.scss */
  .u-sp-border-none {
    border: none; } }

/* line 1, doc/scss/object/utility/_font.scss */
.u-fw-bold {
  font-weight: bold; }

/* line 1, doc/scss/object/utility/_hidden.scss */
.u-hidden {
  display: none; }

/* line 3, doc/scss/object/utility/_margin.scss */
.u-mb-xs {
  margin-bottom: 10px; }

/* line 6, doc/scss/object/utility/_margin.scss */
.u-mb-sm {
  margin-bottom: 20px; }

/* line 9, doc/scss/object/utility/_margin.scss */
.u-mb-md {
  margin-bottom: 30px; }

/* line 12, doc/scss/object/utility/_margin.scss */
.u-mb-lg {
  margin-bottom: 40px; }

/* line 15, doc/scss/object/utility/_margin.scss */
.u-mb-xl {
  margin-bottom: 50px; }

@media all and (max-width: 750px) {
  /* line 21, doc/scss/object/utility/_margin.scss */
  .u-sp-mb-xs {
    margin-bottom: 10px; }
  /* line 24, doc/scss/object/utility/_margin.scss */
  .u-sp-mb-sm {
    margin-bottom: 20px; }
  /* line 27, doc/scss/object/utility/_margin.scss */
  .u-sp-mb-md {
    margin-bottom: 30px; }
  /* line 30, doc/scss/object/utility/_margin.scss */
  .u-sp-mb-lg {
    margin-bottom: 40px; }
  /* line 33, doc/scss/object/utility/_margin.scss */
  .u-sp-mb-xl {
    margin-bottom: 50px; } }

@media all and (min-width: 751px) {
  /* line 40, doc/scss/object/utility/_margin.scss */
  .u-pc-mb-xs {
    margin-bottom: 10px; }
  /* line 43, doc/scss/object/utility/_margin.scss */
  .u-pc-mb-sm {
    margin-bottom: 20px; }
  /* line 46, doc/scss/object/utility/_margin.scss */
  .u-pc-mb-md {
    margin-bottom: 30px; }
  /* line 49, doc/scss/object/utility/_margin.scss */
  .u-pc-mb-lg {
    margin-bottom: 40px; }
  /* line 52, doc/scss/object/utility/_margin.scss */
  .u-pc-mb-xl {
    margin-bottom: 50px; } }

/* line 2, doc/scss/object/utility/_padding.scss */
.u-padding-zero {
  padding: 0; }

@media all and (max-width: 750px) {
  /* line 8, doc/scss/object/utility/_padding.scss */
  .u-sp-padding-zero {
    padding: 0; } }

@media all and (min-width: 751px) {
  /* line 15, doc/scss/object/utility/_padding.scss */
  .u-pc-padding-zero {
    padding: 0; } }

/* line 21, doc/scss/object/utility/_padding.scss */
.u-plr-xs {
  padding-left: 10px;
  padding-right: 10px; }

/* line 26, doc/scss/object/utility/_padding.scss */
.u-plr-sm {
  padding-left: 20px;
  padding-right: 20px; }

/* line 31, doc/scss/object/utility/_padding.scss */
.u-plr-md {
  padding-left: 30px;
  padding-right: 30px; }

/* line 36, doc/scss/object/utility/_padding.scss */
.u-plr-lg {
  padding-left: 40px;
  padding-right: 40px; }

/* line 41, doc/scss/object/utility/_padding.scss */
.u-plr-xl {
  padding-left: 50px;
  padding-right: 50px; }

@media all and (max-width: 750px) {
  /* line 48, doc/scss/object/utility/_padding.scss */
  .u-sp-plr-xs {
    padding-left: 10px;
    padding-right: 10px; }
  /* line 53, doc/scss/object/utility/_padding.scss */
  .u-sp-plr-sm {
    padding-left: 20px;
    padding-right: 20px; }
  /* line 58, doc/scss/object/utility/_padding.scss */
  .u-sp-plr-md {
    padding-left: 30px;
    padding-right: 30px; }
  /* line 63, doc/scss/object/utility/_padding.scss */
  .u-sp-plr-lg {
    padding-left: 40px;
    padding-right: 40px; }
  /* line 68, doc/scss/object/utility/_padding.scss */
  .u-sp-plr-xl {
    padding-left: 50px;
    padding-right: 50px; } }

@media all and (min-width: 751px) {
  /* line 76, doc/scss/object/utility/_padding.scss */
  .u-pc-plr-xs {
    padding-left: 10px;
    padding-right: 10px; }
  /* line 81, doc/scss/object/utility/_padding.scss */
  .u-pc-plr-sm {
    padding-left: 20px;
    padding-right: 20px; }
  /* line 86, doc/scss/object/utility/_padding.scss */
  .u-pc-plr-md {
    padding-left: 30px;
    padding-right: 30px; }
  /* line 91, doc/scss/object/utility/_padding.scss */
  .u-pc-plr-lg {
    padding-left: 40px;
    padding-right: 40px; }
  /* line 96, doc/scss/object/utility/_padding.scss */
  .u-pc-plr-xl {
    padding-left: 50px;
    padding-right: 50px; } }

/* line 103, doc/scss/object/utility/_padding.scss */
.u-pl-xs {
  padding-left: 10px; }

/* line 107, doc/scss/object/utility/_padding.scss */
.u-pl-sm {
  padding-left: 20px; }

/* line 111, doc/scss/object/utility/_padding.scss */
.u-pl-md {
  padding-left: 30px; }

/* line 115, doc/scss/object/utility/_padding.scss */
.u-pl-lg {
  padding-left: 40px; }

/* line 119, doc/scss/object/utility/_padding.scss */
.u-pl-xl {
  padding-left: 50px; }

@media all and (max-width: 750px) {
  /* line 125, doc/scss/object/utility/_padding.scss */
  .u-sp-pl-xs {
    padding-left: 10px; }
  /* line 129, doc/scss/object/utility/_padding.scss */
  .u-sp-pl-sm {
    padding-left: 20px; }
  /* line 133, doc/scss/object/utility/_padding.scss */
  .u-sp-pl-md {
    padding-left: 30px; }
  /* line 137, doc/scss/object/utility/_padding.scss */
  .u-sp-pl-lg {
    padding-left: 40px; }
  /* line 141, doc/scss/object/utility/_padding.scss */
  .u-sp-pl-xl {
    padding-left: 50px; } }

@media all and (min-width: 751px) {
  /* line 148, doc/scss/object/utility/_padding.scss */
  .u-pc-pl-xs {
    padding-left: 10px; }
  /* line 152, doc/scss/object/utility/_padding.scss */
  .u-pc-pl-sm {
    padding-left: 20px; }
  /* line 156, doc/scss/object/utility/_padding.scss */
  .u-pc-pl-md {
    padding-left: 30px; }
  /* line 160, doc/scss/object/utility/_padding.scss */
  .u-pc-pl-lg {
    padding-left: 40px; }
  /* line 164, doc/scss/object/utility/_padding.scss */
  .u-pc-pl-xl {
    padding-left: 50px; } }

/* line 1, doc/scss/object/utility/_text.scss */
.u-text-right {
  text-align: right; }

/* line 5, doc/scss/object/utility/_text.scss */
.u-text-center {
  text-align: center; }

/* line 9, doc/scss/object/utility/_text.scss */
.u-text-indent {
  text-indent: 1em; }
